import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { Container } from '../components/Theme';

const  ComplaintsProcedure = () => (
  <Layout>
    <Helmet
      title='Metro Communications Complaints Procedure'
      meta={[
        { name: 'description', content: `We are committed to providing a high-quality service to our customers, suppliers and business partners. So, if something does go wrong, please tell us as soon as possible so we can put it right. We will try to sort out your complaint within eight weeks. And if we haven’t been able to sort it out by then, you can complain to Ombudsman Services.` },
        { name: 'keywords', content: `` },
      ]}
    />
    <Container>
      <h1>Complaints Procedure</h1>
      <p>We are committed to providing a high-quality service to our customers, suppliers and business partners. So, if something does go wrong, please tell us as soon as possible so we can put it right. We will try to sort out your complaint within eight weeks. And if we haven’t been able to sort it out by then, you can complain to Ombudsman Services.</p>
      <p>Our complaints procedure has three stages:</p>
      <ol>
        <li>Customer services reviews the complaint</li>
        <li>A senior manager reviews the complaint</li>
        <li>We make a final decision</li>
      </ol>
      <h3>Customer services reviews the complaint</h3>
      <p>To tell us about your complaint, please call Customer Services on 0203 906 1400 and choose option 2 or send an email to customer.services@metrocomms.co.uk. Please explain the problem, give us as much information as you can, and tell us what you want to happen. We will give you a reference number and try to sort out the problem there and then.</p>
      <p>If we aren’t able to sort out the problem straight away, we will get back to you within three business days (Monday to Friday) of receiving your complaint. We will contact you by email or phone to explain how we plan to solve the problem.</p>
      <h3>A senior manager reviews the complaint</h3>
      <p>If you are not happy with our answer to your complaint, we will pass your complaint to a senior manager. Then, the manager will review your complaint and arrange to speak with you. We will do this within 14 business days of you telling us you are not happy.</p>
      <p>Within three days of speaking with you, we will write to you to confirm what has taken place and set out the solutions we have agreed with you.</p>
      <p>If you are still not happy with our solutions, please contact us again to tell us why. We will then review your reply.</p>
      <h3>We make a final decision</h3>
      <p>If you have asked us to review our solution to your complaint, we will reply within 14 business days to give you our final decision and explain our reasons.</p>
      <p>If you are still not happy with our answer and it has been eight weeks since you first contacted us about your complaint, you can ask the Ombudsman to do an independent review. You will need to contact the Ombudsman within six months of receiving our final decision in writing.</p>
      <h3>How to contact the Ombudsman</h3>
      
      <h4>Post</h4>
      <p>
        Ombudsman Services: Communications<br />
        3300 Daresbury Park<br />
        Daresbury<br />
        WARRINGTON<br />
        WA4 4HS
      </p>
      <h4>Website</h4>
      <p><a href="https://www.ombudsman-services.org/">www.ombudsman-services.org</a></p>
      <h4>Email</h4>
      <p><a href="mailto:osenquiries@os-communications.org">osenquiries@os-communications.org</a></p>
      <h4>Phone</h4>
      <p>0330 440 1614</p>
    </Container>
  </Layout>
);

export default ComplaintsProcedure;